<template>
  <el-dialog :visible.sync='showDialog' v-bind='$attrs' v-on='$listeners' :title='title' width='800px'>
    <div>
      <!--        表单-->
      <el-form :model='formData' label-width='120px' ref='elForm' :rules='rules' size='small'>
        <el-form-item label='红人昵称' prop='nickname'>
          <artist-search v-if='!formData.id' style='width: 100%' @handleSelect='selectArtist'></artist-search>
          <!--              <el-input type='text' v-model='formData.nickname' placeholder='红人昵称' clearable />-->
          <span v-else>{{ formData.nickname }}</span>
        </el-form-item>
        <el-form-item label='带货产品名'>
          <el-input type='text' v-model='formData.product_name' placeholder='产品名' :disabled='!editPermission'
                    maxlength='200' show-word-limit clearable />
        </el-form-item>
        <el-form-item label='上线日期'>
          <el-date-picker
            v-model='formData.launch_date'
            type='date'
            format='yyyy年MM月dd日'
            value-format='yyyy-MM-dd'
            placeholder='选择上线日期' :disabled='!editPermission' clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item label='售价'>
          <el-input type='text' v-model='formData.price' placeholder='售价，金额保留2位小数'
                    oninput="value=value.replace(/[^\d^\.^\-]/g,'')" :disabled='!editPermission'
                    clearable />
        </el-form-item>
        <el-form-item label='销售量'>
          <el-input type='text' v-model='formData.sales_qty' placeholder='销售量,数值'
                    oninput="value=value.replace(/[^\d^\.^\-]/g,'')" :disabled='!editPermission' clearable />
        </el-form-item>
        <el-form-item label='截至本月销售额'>
          <el-input type='text' v-model='formData.month_amount' placeholder='截至本月销售额'
                    oninput="value=value.replace(/[^\d^\.^\-]/g,'')" :disabled='!editPermission' clearable />
        </el-form-item>
        <el-form-item label='统计截止日期'>
          <el-date-picker
            v-model='formData.end_date'
            type='date'
            format='yyyy年MM月dd日'
            value-format='yyyy-MM-dd'
            placeholder='选择统计截止日期' :disabled='!editPermission' clearable>
          </el-date-picker>
        </el-form-item>
        <el-form-item label='视频内容'>
          <el-input type='url' v-model='formData.source_link' placeholder='视频内容,网址 https://example.com'
                    :disabled='!editPermission' maxlength='500' show-word-limit clearable />
        </el-form-item>
        <el-form-item>
          <el-row>
            <el-col :span='12'>
              <el-button type='primary' @click='handleConfirm' :disabled='loadingCommit'
                         :loading='loadingCommit'
                         v-if='canEdit'>
                保存
              </el-button>
              <el-button type='default' :disabled='true' v-else>
                保存(无权限)
              </el-button>
            </el-col>
            <el-col :span='12' style='text-align: right'>
              <el-button type='danger' icon='el-icon-delete' v-if='canEdit&&formData.id'
                         @click="handleCmd('del')">删除
              </el-button>
            </el-col>
          </el-row>

        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>

import { mapGetters } from 'vuex'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import ArtistSearch from '@/components/artist/ArtistSearch'

export default {
  name: 'CreateOrEdit',
  components: {
    ArtistSearch,
    ArtistSearchCustom
  },
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo'])

  },
  data() {
    return {
      showDialog: false,
      title: '新增抖音视频案例',
      searchCondition: { artist_id: null, nickname: null, group_id: null, group_name: null },
      artists: [],
      formData: {
        artist_id: null,
        nickname: null,
        product_name: null,
        launch_date: null,
        price: null,
        sales_qty: null,
        month_amount: null,
        source_link: null
      },
      loadingCommit: false,
      rules: {
        nickname: [{
          required: true,
          message: '红人昵称不能为空',
          trigger: 'change'
        }]
      },
      canEdit: true,
      editPermission: true,
      scopeType: 'all'
    }
  },
  methods: {
    show(row) {
      this.showDialog = true
      if (row && row.id) {
        this.title = `修改抖音视频案例（红人昵称：${row.nickname}）`
        this.formData = { ...row }
      } else {
        this.formData = {}
        this.title = '新增抖音视频案例'
      }
      this.init()
    },
    init() {
      this.scopeType = this.calcScopeType()
    },
    initVar() {
      this.formData = {}
      this.searchCondition = { artist_id: null, nickname: null, group_id: null, group_name: null }
      this.editPermission = true
      this.artists = []
    },
    calcScopeType() {
      if (this.userPermissions.indexOf('case_record_data_company') > -1) {
        return 'all'

      } else if (this.userPermissions.indexOf('case_record_data_dpt') > -1) {
        // 当前选中的组在本部及其组，则有权限修改
        return 'dpt'

      } else if (this.userPermissions.indexOf('case_record_data_group') > -1) {
        // 当前选中的组在个人归属的组（一个人可在多组），则有权限修改
        return 'group'
      }
      return 'group'
    },
    close() {
      this.showDialog = false

    },
    handleConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return

        this.loadingCommit = true
        this.saveInfo()
      })
    },
    async saveInfo() {
      await this.$api.saveCaseRecord(this.formData)
      this.loadingCommit = false
      this.$emit('saved')
      this.close()
    },
    doSearch() {

      this.formData.nickname = this.searchCondition.nickname
      this.formData.artist_id = this.searchCondition.artist_id
      // console.log('change formData artist nickname', this.formData.nickname)
      this.canEdit = this.calcPermission(this.formData.group_id)
    },
    async selectGroup(val) {
      this.searchCondition.group_id = val
      await this.getGroupArtists()

    },
    async changeArtist() {
      // 红人
      let artist = this.artists.find((value) => value.id === this.searchCondition.artist_id)
      this.searchCondition.nickname = artist ? artist.nickname : ''
      // console.log('search artist', this.searchCondition.nickname)
      this.doSearch()
    },
    async getGroupArtists(type) {
      this.searchCondition.artist_id = null
      this.searchCondition.nickname = null
      let groupId = this.searchCondition.group_id
      let { list } = await this.$api.getGroupArtists(groupId)
      this.artists = list
      if (type !== 'search' && list.length > 0) {
        this.searchCondition.artist_id = list[0].id
        await this.changeArtist()
      }
    },
    async selectArtist(obj) {
      this.formData.artist_id = obj.id
      this.formData.nickname = obj.nickname
      this.formData.group_id = obj.group_id
      this.formData.group_name = obj.group_name

    },

    //检索红人
    async chooseArtist(item) {
      if (item) {
        this.searchCondition.group_id = item.group_id
        this.searchCondition.group_name = item.group_name
        await this.getGroupArtists('search')
        this.searchCondition.artist_id = item.id
        this.searchCondition.nickname = item.nickname
        await this.changeArtist()
      } else {
        this.searchCondition.artist_id = null
        this.doSearch()
      }
    },
    //计算权限
    calcPermission(currentGroupId) {
      //录入权限
      let hasPermission = this.userPermissions.indexOf('case_record_tiktok_enter') > -1
      //范围权限
      let rangePermission = false

      // let currentGroupId = this.searchCondition.group_id
      //数据范围
      //有全公司 数据权限
      if (this.userPermissions.indexOf('case_record_data_company') > -1) {
        rangePermission = true

      } else if (this.userPermissions.indexOf('case_record_data_dpt') > -1) {
        // 当前选中的组在本部及其组，则有权限修改
        let dptRange = this.userDptInfo.dpt_range
        rangePermission = dptRange ? dptRange.indexOf(currentGroupId) > -1 : false

      } else if (this.userPermissions.indexOf('case_record_data_group') > -1) {
        // 当前选中的组在个人归属的组（一个人可在多组），则有权限修改
        let dpt_ids = this.userDptInfo.my_dpt_range ? this.userDptInfo.my_dpt_range : []
        rangePermission = dpt_ids ? dpt_ids.indexOf(currentGroupId) > -1 : false

      } else {
        rangePermission = false
      }

      // console.log(`组：${ this.searchCondition.group_id }, hasPermission:${ hasPermission }`)
      return hasPermission && rangePermission
    },
    async getArtistInfo(id) {
      let { info } = await this.$api.getArtistInfo(id)
      if (info) {
        await this.chooseArtist({
          id: info.id,
          nickname: info.nickname,
          group_id: info.group_id,
          group_name: info.group_name
        })
        //如果没有数据权限，则不能修改数据
        this.editPermission = this.calcPermission(info.group_id)
      }
    },
    async copy() {
      if (this.formData.id) {
        let postData = { ...this.formData }
        delete postData.id
        // console.log('post data', postData)
        await this.$api.saveCaseRecord(postData)
        this.loadingCommit = false
      }

      // this.close()
    },
    handleCmd(cmd) {
      switch (cmd) {
        case 'copy':
          this.copy()
          break
        case 'edit':
          // this.$emit('editRow', this.currentRow)
          break
        case 'del':
          this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delRow()

          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
          break
      }
    },
    async delRow() {
      await this.$api.delCaseRecord(this.formData.id)
      this.$emit('saved')
      this.close()
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
