<template>
  <div>
    <div class='main-page-content'>
      <div class='search flex-sb'>
        <el-form :model='searchCondition' :inline='true' size='mini'>
          <el-form-item>
            <el-date-picker
              v-model='searchCondition.ym'
              type='month'
              format='yyyy年MM月'
              value-format='yyyy-MM'
              placeholder='选择月度' style='width: 140px' @change='getOptions'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select v-model='searchCondition.group_id' @change='handlerQuery' :placeholder='`可选项目组`' clearable>
              <el-option v-for='(group,key) in options' :key='key' :value='group.group_id'
                         :label='group.group_name'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='handlerQuery' icon='el-icon-search'>查询</el-button>

            <el-button icon='el-icon-plus' type='primary' @click='handleAdd'>新增
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-link class='op-link' type='primary' @click='showLog'
                     v-if="userPermissions.indexOf('case_record_log') !== -1">
              日志
              <i class='el-icon-document'></i></el-link>
          </el-form-item>
        </el-form>
        <div>
          <!--        导出表格-->
          <export-link ref='refExportLink' :can-export="userPermissions.indexOf('case_record_export') !== -1"
                       @export='exportData' />
        </div>
      </div>
      <div>
        <div class='default-table' v-loading='loadingStatus'>
          <el-table :data='dataList'
                    @row-contextmenu='openMenu'
                    @sort-change='changeTableSort'
                    :summary-method='getSummaries'
                    show-summary
                    highlight-current-row
                    border>
            <el-table-column label='序号' type='index' width='55'></el-table-column>
            <el-table-column prop='group_name' label='组别' min-width='100' align='center' show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop='nickname' label='红人昵称' min-width='120' align='center' show-overflow-tooltip
                             sortable='custom'>
            </el-table-column>
            <el-table-column prop='product_name' label='带货产品名' min-width='200' header-align='center' align='center'
                             show-overflow-tooltip>
              <template slot-scope='scope'>
                <span>{{ scope.row.product_name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='launch_date' label='上线日期' min-width='100' header-align='center' align='center'
                             sortable='custom' show-overflow-tooltip>
              <template slot-scope='scope'>
                <span>{{ scope.row.launch_date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='price' label='售价' min-width='120' header-align='center' align='right'
                             sortable='custom'
                             show-overflow-tooltip>
              <template slot-scope='scope'>
                <span>{{ moneyFormat(scope.row.price) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='sales_qty' label='销售量' min-width='120' header-align='center' align='right'
                             sortable='custom'
                             show-overflow-tooltip>
              <template slot-scope='scope'>
                <span>{{ numFormat(scope.row.sales_qty) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='month_amount' label='截至本月销售额' min-width='120' header-align='center' align='right'
                             sortable='custom' show-overflow-tooltip>
              <template slot-scope='scope'>
                <span>{{ moneyFormat(scope.row.month_amount) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='end_date' label='统计截止时间' min-width='100'
                             header-align='center' align='center'
                             sortable='custom' show-overflow-tooltip>
              <template slot-scope='scope'>
                <span>{{ scope.row.end_date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='created_at' label='录入时间' min-width='100' header-align='center' align='center'
                             sortable='custom' show-overflow-tooltip>
              <template slot-scope='scope'>
                <span>{{ scope.row.created_at }}</span>
              </template>
            </el-table-column>
            <el-table-column prop='source_link' label='视频内容' min-width='120' align='center' show-overflow-tooltip>
              <template slot-scope='scope'>
                <el-link type='primary' :href='scope.row.source_link' target='_blank'>
                  下载
                  <i class='el-icon-download el-icon--right'></i>
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label='#' align='center' width='80'>
              <template slot-scope='{row}'>
                <el-link type='primary' icon='el-icon-edit-outline' @click='handleEdit(row)'></el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <m-pagination :total.sync='total' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                      @pagination='getList'></m-pagination>
      </div>
    </div>
    <!--    右键菜单-->
    <Contextmenu :menu-data='menuData' @change='handleCmd' />
    <CreateOrEdit ref='refCreateOrEdit' @saved='getList'></CreateOrEdit>

    <!--    日志-->
    <CaseRecordLog :visible='logSetting.visible' @close='closeLog' :info-id='logForm.info_id||null'
                   v-if="userPermissions.indexOf('case_record_log') !== -1" />
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { numberFormat } from '@/utils/utils'
import Contextmenu from './Contextmenu'
import { mapGetters } from 'vuex'
import CreateOrEdit from '@/pages/pa/case/CreateOrEdit'
import ExportLink from '@/components/export/ExportLink'
import CaseRecordLog from './CaseRecordLog'

export default {
  name: 'VideoCase',
  components: { CaseRecordLog, ExportLink, CreateOrEdit, Contextmenu, PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions', 'userDptInfo'])
  },
  data() {
    return {
      loadingStatus: false,
      fileTitle: '',
      searchCondition: { group_id: null, ym: null, start_time: null, end_time: null },
      options: [],
      dataList: [],
      pageData: { current_page: 1, page_size: 10 },
      total: 0,
      orderSort: { id: 'desc' },//默认排序规则
      //鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      currentRow: {},
      currentColumn: {},
      logSetting: {
        visible: false
      },
      logForm: {}
    }
  },
  methods: {
    defaultTime() {
      const end = new Date();
      //年份选择：默认当月
      // this.searchCondition.year = end.getFullYear()
      // this.searchCondition.month = end.getMonth() + 1
      this.searchCondition.ym = end.getFullYear() + '-' + (end.getMonth() + 1)
      this.getOptions()
    },
    rateFormat(value) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    moneyFormat(value) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    numFormat(value) { // 数值 格式化
      return numberFormat(value, 0, '.', ',', 'round')
    },
    async getOptions() {
      this.options = []
      let searchCondition = this.handleSearchCondition()
      let { list } = await this.$api.getCaseRecordOption(searchCondition)
      this.options = list || []
      let posIndex = this.options.findIndex(value => value.group_id === this.searchCondition.group_id)
      if (posIndex === -1) {
        this.searchCondition.group_id = null
      }
    },

    handlerQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    async getList() {
      this.loadingStatus = true

      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData, { order: this.orderSort })
      this.dataList = []
      let { list, pages } = await this.$api.getCaseRecordList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.total = pages.total || 0
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 10
      // this.offset = pages.offset
      this.loadingStatus = false
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      this.fileTitle = '案例记录数据'

      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }

      // if (this.searchCondition.artist_id) {
      //   condition.artist_id = this.searchCondition.artist_id
      // }

      if (this.searchCondition.ym) {
        let ymArr = this.searchCondition.ym.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0)
        let daycount = day.getDate()//最后一天
        condition.start_time = this.searchCondition.ym + '-01'
        condition.end_time = this.searchCondition.ym + '-' + daycount
      }
      return condition
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计：'
          return
        }
        if (['month_amount', 'sales_qty'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' '
          } else {
            sums[index] = 'N/A'
          }

          switch (column.property) {
            case 'sales_qty':
              sums[index] = this.numFormat(sums[index])
              break
            case 'month_amount':
              sums[index] = '¥ ' + this.moneyFormat(sums[index])
              break
            default:
              // sums[index] = this.valFormat(sums[index])
              break
          }
        }

      })

      return sums
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()
    },
    async delRow() {
      await this.$api.delCaseRecord(this.currentRow.id)
      await this.getList()
    },
    async copy() {
      if (this.currentRow.id) {
        let postData = { ...this.currentRow }
        delete postData.id
        // console.log('post data', postData)
        await this.$api.saveCaseRecord(postData)
        await this.handlerQuery()
      }

      // this.close()
    },
    handleCmd(cmd) {
      // this.$notify.info(`cmd:${ cmd }`)
      switch (cmd) {
        case 'copy':
          this.copy()
          break
        case 'edit':
          this.handleEdit(this.currentRow)

          break
        case 'del':
          this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delRow()

          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
          break
        case 'log':
          // this.$message.warning('查看日志')
          this.$emit('showLog', this.currentRow)
          break
      }
    },
    openMenu(row, column, e) {
      this.currentRow = row
      this.currentColumn = column
      if (this.userPermissions.indexOf('case_record_tiktok_enter') !== -1) {
        // const offsetWidth = this.$el.offsetWidth; // container width
        e.preventDefault()//阻止元素发生默认的行为

        // const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left
        this.menuData = {
          visible: true,
          left: e.clientX,
          top: e.clientY
        }
        document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      }
    },
    //右键菜单：设置是否月考核
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false
        document.removeEventListener('click', this.closeMenu)
      }
    },

    handleAdd() {
      this.$refs['refCreateOrEdit'].show()
    },
    handleEdit(currentRow) {
      this.$refs['refCreateOrEdit'].show(currentRow)
    },
    //导出表格
    async exportData() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportCaseRecord(searchCondition)
        let name = `抖音视频案例`
        if (this.searchCondition.group_id) {
          name += `-${this.searchCondition.group_name}`
        }
        if (this.searchCondition.ym) {
          name += `-${this.searchCondition.ym}`
        }
        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    showLog() {
      this.logForm = {}
      this.logSetting.visible = true
    },
    closeLog() {
      this.logSetting.visible = false
    }

  },
  mounted() {
    this.defaultTime()
    this.handlerQuery()
  }
}
</script>

<style scoped>

</style>
